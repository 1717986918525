import React, { useContext, useEffect, useState } from "react"
import './my-referral.scss';
import {FacebookShareButton, WhatsappShareButton, TwitterShareButton, FacebookIcon, TwitterIcon, WhatsappIcon} from 'react-share'
import { navigate } from "gatsby"
import ApplicationContext from "../../state/ApplicationContext";
import personalCircle from '../../assets/icons/personal-circle.svg';
import yourGift from '../../assets/icons/your-gift.svg';
import {injectIntl} from 'gatsby-plugin-intl';
import classNames from 'classnames';
import Header from "../../components/common/Header"
import { Helmet } from "react-helmet"

const checkPoints = [
  {
  count: 5,
  text: '2 free Powerball Tickets',
  },
  {
    count: 10,
    text: '1 month free Powerball Tickets',
  },
  {
    count: 25,
    text: '€100 Lotteroo Voucher',
  },
  {
    count: 50,
    text: '6 months free Powerball Tickets',
  }
]

const MyReferral = ({intl: {locale, formatMessage}, ...props}) => {
  const {
    handleGrowl, setShowSignIn, user, isDesktop, userIsChecked
  } = useContext(ApplicationContext);
  console.log(props);
  const currentLocale = locale === 'en' ? '/': `/${locale}`;
  const translate = (message) => formatMessage({id: message});

  useEffect(() => {
    document.querySelector('html').classList.add('no-footer');
    // document.querySelector('html').classList.add('no-header');
    return () => {
      document.querySelector('html').classList.remove('no-footer');
      // document.querySelector('html').classList.remove('no-header');
    }
  }, [])

  useEffect(() => {
    const userData = localStorage.getItem('userData');
    if (!userData) {
      handleGrowl(true, translate('To view this page, you must log in to your account'), false);
      navigate(currentLocale);
      setShowSignIn(true);
    }
  }, [user])

  const sharingUrl = typeof window !== 'undefined' && `${window?.location.origin}/free-tickets/?ref=${user?.idReferral}`;

  const scalePercentage = () => {
    const refs = user?.referrals;
    if(!refs) return 100;
    switch (true) {
      case refs.length === 0:
        return 0;
      case refs.length < 5:
        return isDesktop ? 18 : 8;
      case refs.length === 5:
        return isDesktop ? 33 : 18;
      case refs.length < 10:
        return isDesktop ? 45 : 31;
      case refs.length === 10:
        return isDesktop ? 55 : 42;
      case refs.length < 25:
        return isDesktop ? 65 : 55;
      case refs.length === 25:
        return isDesktop ? 75 : 65;
      case refs.length < 50:
        return isDesktop ? 85 : 80;
      case refs.length === 50:
        return 92;
      default:
        return 100;
    }
  };

  return (<div className={'thank-you-wrapper'}>
    <Helmet>
      <title>
        Lotteroo | {translate('My Referral')}
      </title>
    </Helmet>
    {/*<Header location={props.location} userIsChecked={userIsChecked}/>*/}
    <div className={'content'}>
      <div className="text">
        <div className="title">
         <h2 id="winning-is-more-fun-with-friends">{translate('Winning is more fun with friends')}</h2>
            <h1 id="invite-friends-earn-tickets">{translate('InviteFriendsText')}</h1>
            <p>{translate('ShareText')}</p>
        </div>
        <div className="share">
          <div className="link">
            <div className="ttl">
              {translate('Unique Link')}
            </div>
            <div className="form">
              <input type="text" value={sharingUrl}/>
            </div>
          </div>
          <div className="link">
            <div className="ttl">
              {translate('Share')}
            </div>
            <div className={'soc ui-g'}>
              <div className="ui-sm-6 ui-md-3 ui-lg-3">
                <FacebookShareButton url={sharingUrl}>
                  Facebook
                </FacebookShareButton>
              </div>
              <div className="ui-sm-6 ui-md-3 ui-lg-3">
                <TwitterShareButton url={sharingUrl}>
                   Twitter
                </TwitterShareButton>
              </div>
              <div className="ui-sm-6 ui-md-3 ui-lg-3">
                <WhatsappShareButton url={sharingUrl}>
                 Whatsapp
                </WhatsappShareButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="footer">
      <div className="inner">
        <div className="joined">
          <div className="title friends-joined">
            <img src={personalCircle} alt=""/>
            <span>  {translate('Friends Joined')}</span>
          </div>
          <div className="scale">
            <div className="line">
              <div className="green" style={{
                width: scalePercentage() + '%'
              }}></div>
              <div className="green vertical" style={{
                height: `calc(1% + ${scalePercentage()})`
              }}></div>
            </div>

            <div className="checkpoints">
              {checkPoints.map((element, index) => {
                return (
                  <div
                    className={classNames("item", 'item' + index, {
                      achieved: user?.referrals >= element.count
                    })}>
                    <div className="point">{element.count }</div>
                    <div className="description">{translate(element.text) }</div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
        <div className="friends">
          <div className="title gift">
            <img src={yourGift} alt=""/>
            <span>{translate('Your gift')}</span>
          </div>
          <div className="centered">
            {user?.referrals.length > 0 ?  <>
              <h3>{user?.referrals.length} {translate('Friends Joined')}</h3>
              <p>{translate('Good Job!')}</p>
            </>: <>
             <h3 id="no-friends-have-joined-yet">{translate('No friends have joined yet')}</h3>
                <p>{translate('Keep checking...')}</p>
              </>}
          </div>
          {user?.referrals.length > 0 && <div className={'list'}>
            <ul>
              {user?.referrals.map((element, index) => {
                return (
                  <li>
                    <div className="person">
                      <span className="name">{ index+1}. { element.fullName }</span>
                      <span className="email">{ element.email }</span>
                    </div>
                    {(index==4 || index==9 || index==24 || index==49) && <>
                      {
                        checkPoints.map((point) => {
                          if(index + 1 === point.count){
                            return <div className={'gift-wrapper'}>
                              <div className={'gift'}>{translate(point.text)}</div>
                            </div>
                          }
                        })
                      }
                    </>
                    }
                  </li>
                )
              }) }
            </ul>
          </div>}
        </div>
      </div>
    </div>
  </div>)
};

export default injectIntl(MyReferral);
